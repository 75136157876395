import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Garden } from '../../shared/auth/garden.interface';
import { AuthService } from '../../shared/auth/auth.service';

@Component({
  selector: 'inlite-garden-select',
  templateUrl: './garden-select.component.html',
  styleUrls: ['./garden-select.component.css'],
})
export class GardenSelectComponent {
  email = '';
  gardens: Garden[] = []
  selected: string = '';
  passedState;
  connectError;
  isLoading = false;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
  ) {
    this.passedState = this.router.getCurrentNavigation().extras?.state || {};

    const { loginResult } = this.passedState;
    if (!loginResult) {
      this.router.navigate(['/sign-in']);
    } else {
      this.email = loginResult.email;
      this.gardens = loginResult.gardens;
    }

    this.selected = this.gardens[0]._id;
  }

  public async connect(): Promise<void> {
    this.isLoading = true;

    try {
      const dto = { gardenId: this.selected, email: this.email };
      const authCode = await this.authService.createAuthCode(dto);
      this.redirectBackToAssistant(authCode);
    } catch (err) {
      const error = err?.error || {};
      const { message } = error;
      this.connectError = message ? message : 'Something went wrong';
    }
    this.isLoading = false;
  }

  private redirectBackToAssistant(authCode: string) {
    const { redirectUri, state } = this.passedState;
    window.location.href = `${decodeURIComponent(redirectUri)}?code=${authCode}&state=${state}`;
  }
}
